@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue'), url('./BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansRegular';
  src: local('NotoSansRegular'), url('./NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: local('NotoSansBold'), url('./NotoSans-Bold.ttf') format('truetype');
}